<template>
    <div id="recruitment">
        <div class="content">
            <h2 class="title" v-if="lxwm">{{ isEn ? lxwm.enName : lxwm.cnName }}</h2>
            <h2 v-if="lxwm && lxwm.data">{{ isEn ? lxwm.data.enTitle : lxwm.data.cnTitle }}</h2>
            <div class="contact" v-if="lxwm && lxwm.data">
                <div class="baseinfo">
                    <div class="lf">
                        <p v-html="isEn ? lxwm.data.enContent : lxwm.data.cnContent"></p>
                    </div>
                    <div class="rf">
                        <img :src="staticPath + (isEn ? lxwm.data.enLogo : lxwm.data.cnLogo)" alt="">
                    </div>
                </div>
                <div class="map">
                    <img :src="lxwm.data.files.length > 0 ? staticPath + lxwm.data.files[0].fileUrl : ''" alt="">
                </div>
            </div>
            <div class="message">
                <img :src="require('@/assets/images/1093.png')" alt="">
                <div class="qrcode">
                    <img :src="require('@/assets/images/qrcode.png')" alt="">
                    <p>请用微信扫码联系在线客服</p>
                </div>
                <!--<h2 class="badge">客户留言</h2>
                <span>您的反馈是我们最大的进步动力</span>
                <div class="form">
                    <el-form ref="form" :model="form" label-width="106px">
                        <el-form-item label="姓名:" required>
                            <el-input placeholder = "请输入您的名字" v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话:" required>
                            <el-input placeholder = "请输入您的联系电话号码" v-model="form.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="电子邮箱:">
                            <el-input placeholder = "请输入您的电子邮箱" v-model="form.email"></el-input>
                        </el-form-item>
                        <el-form-item label="留言类型:">
                            <el-select v-model="form.type" placeholder="请选择留言类型">
                                <el-option label="玩具产品咨询" value="玩具产品咨询"></el-option>
                                <el-option label="投资者交流" value="投资者交流"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="留言内容:" required>
                            <el-input placeholder = "请输入您的留言内容" type="textarea" v-model="form.content"></el-input>
                        </el-form-item>
                        <el-form-item align="center">
                            <el-button type="primary" @click="submit">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>-->
            </div>
            <div class="hire" v-if="rczp">
                <h2 class="badge">{{ isEn ? rczp.enName : rczp.cnName }}</h2>
                <div class="info">
                    <div class="lf">
                        <img :src="require('../../assets/images/service_robot.png')" alt="">
                    </div>
                    <div class="rf">
                        <img :src="require('../../assets/images/about/bg_strategy.png')" alt="">
                        <div class="text">
                            <p>{{ isEn ? rczp.data[0].enTitle : rczp.data[0].cnTitle }}</p>
                            <p v-html="isEn ? rczp.data[0].enContent : rczp.data[0].cnContent"></p>
                        </div>
                        <span @click="toRecruitment" class="icon-more"><span>{{ $t('lang.see_more') }}</span></span>
<!--                        <router-link :to="rczp.templateUrl" class="icon-more"></router-link>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {urls, staticPath} from '@/lib/common'
    export default {
        name: "Service",
        data() {
            return {
                isEn: false,
                staticPath,
                form:{
                    name: "",
                    phone: "",
                    email: "",
                    type: "",
                    content: ""
                },
                navId: '',
                lxwm: '',
                rczp: ''

            }
        },
        watch: {
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            submit() {
                if (!this.form.name) {
                    this.$message.warning('请输入姓名');
                    return false
                }
                if (!this.form.phone) {
                    this.$message.warning('请输入联系电话');
                    return false
                }
                if (!this.form.content) {
                    this.$message.warning('请输入留言内容');
                    return false
                }
                const params = this.form;
                this.$http.post(urls.createMessage, params).then(res => {
                    if (res.data.status === 200) {
                        this.$message.success('留言成功');
                        this.form = {
                            name: "",
                            phone: "",
                            email: "",
                            type: "",
                            content: ""
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            toRecruitment() {
                this.$router.push({
                    path: this.rczp.templateUrl,
                    query: {
                        n: btoa(encodeURIComponent(this.rczp.data[0].id)),
                        p: btoa(encodeURIComponent(this.rczp.parentId))
                    }
                })
            },
            getTemp() {
                this.$http.get(urls.getCustomerTemp + this.navId).then(res => {
                    console.log(res);
                    if (res.data.status === 200) {
                        this.lxwm = res.data.data.lxwm;
                        this.rczp = res.data.data.rczp;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        mounted() {
            this.navId = window.atob(decodeURIComponent(this.$route.query.n))
            console.log(this.navId);
            this.getTemp()
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #recruitment {
        .content {
            line-height: 200%;
            .badge {
                font-size: 28px;
                text-align: center;
                color: #fff;
                padding: 3vw 0 1vw;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 2.34vw;
                    height: 1.98vw;
                    background: url('../../assets/images/about/icon.png') no-repeat center center;
                    background-size: 100% 100%;
                    position: relative;
                    top: -0.8vw;
                    right: 0.3vw;
                }
                &+span {
                    color: rgba(255,255,255,0.7);
                    font-size: 16px;
                }
            }

            >h2 {
                padding: 0 20vw;
                font-size: 18px;
                margin-bottom: 2vw;
            }
            .title {
                margin-bottom: 3vw;
                font-size: 28px;
            }
            .contact {
                padding: 0 20vw;
                background: url("../../assets/images/service_bg1.png") no-repeat center center;
                background-size: 100% 100%;
                .baseinfo {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    .lf {
                        font-size: 18px;
                        line-height: 200%;
                    }
                    .rf {
                        position: absolute;
                        top: -4vw;
                        right: 0;
                        padding: 5px;
                        img {
                            max-width: 200px;
                        }
                    }
                }
            }
            .message {
                text-align: center;
                background-size: 100% 100%;
                position: relative;
                .qrcode {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -8.854vw;
                    margin-top: -11.458vw;
                    >img {
                        width: 15.625vw;
                    }
                    p {
                        color: #fff;
                        font-size: 1.145vw;
                        width: 17.968vw;
                        height: 4.6875vw;
                        background: url("../../assets/images/10234.png") no-repeat center center;
                        background-size: 100% 100%;
                        line-height: 5.468vw;
                    }
                }
                /*@{deep}.form {
                    width: 760px;
                    margin:0 auto;
                    margin-top: 30px;
                    .el-select{
                        width: 100%;
                    }
                    .el-form-item__label {
                        color: #fff;
                        font-size: 18px;
                    }
                    .el-textarea {
                        height: 140px;
                        .el-textarea__inner {
                            resize: none;
                        }
                        textarea {
                            height: 100%;
                        }
                    }
                }*/
            }
            .hire {
                padding: 3vw 20vw 5vw;
                background: url("../../assets/images/service_bg3.png") no-repeat center center;
                background-size: 100% 100%;
                .badge {
                    color: #353968;
                }
                .info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .lf {
                        flex: 1;
                        position: relative;
                        bottom: 4vw;
                    }
                    .rf {
                        flex: 2;
                        position: relative;
                        font-size: 1.25vw;
                        .text {
                            width: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            padding: 5% 8% 0;
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            line-height: 180%;
                        }
                        .icon-more {
                            display: inline-block;
                            width: 1.458vw;
                            height: 0.9375vw;
                            cursor: pointer;
                            background: url('../../assets/images/about/icon_more.png') no-repeat center center;
                            background-size: 100% 100%;
                            position: absolute;
                            bottom: 5vw;
                            left: 3vw;
                            span{display: none;}
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px){
        #recruitment{
            .content{
                &>h2{
                    .fontFn(16);
                    padding: 0 5.2%;
                }
                .title{
                    .fontFn(18);

                }
                .badge{
                    .fontFn(18);
                    &::after{
                        .fontFn(16,width);
                        .fontFn(14,height);
                        top:-10px;
                    }
                }
                .contact{
                    padding: 0;
                    margin-bottom: 10%;
                    .baseinfo{
                        .lf{
                            .fontFn(14);
                            padding: 0 40% 0 5.2%;
                        }
                        .rf{
                            padding: 0;
                            top: -4%;
                            img{
                                .fontFn(150,width);
                            }
                        }
                    }
                    .map{padding: 0 2.6%}
                }
                .message{
                    padding: 0;
                    background:#F5FDFF url('../../assets/images/phone/module_bg11.png') no-repeat center top;
                    background-size: 100% auto;
                    .fontFn(530,min-height);
                    h2{
                        padding-top: 12%;
                    }
                    span{
                        color:#aaa;
                        .fontFn(14);
                    }
                    @{deep}.form {
                        margin:0;
                        padding-top: 6%;
                        width: 100%;
                        padding-right: 8%;
                        padding-left: 2%;
                        .el-select{
                            width: 100%;
                        }
                        .el-input__icon{
                            line-height: 1;
                        }
                        .el-form-item__label {
                            color: #fff;
                            .fontFn(30,line-height);
                            .fontFn(12);
                        }
                        .el-textarea {
                            .fontFn(100,height);
                            .el-textarea__inner {
                                resize: none;
                            }
                            textarea {
                                height: 100%;
                            }
                        }
                        .el-input{
                            width: 100%;
                            font-size: 12px;
                        }
                        .el-form-item{
                            margin-bottom: 6%;
                        }
                        .el-form-item__content{
                            .fontFn(30,line-height);
                        }
                        .el-input__inner{
                            .fontFn(30,height);
                            .fontFn(30,line-height);
                        }
                        .el-button{
                            padding: 8px 20px;
                        }
                    }
                }
                .hire{
                    padding: 2% 0;
                    .badge{
                        padding-bottom: 6%;
                    }
                    .info{
                        .lf{
                            display: none;
                        }
                        .rf{
                            .text{
                                padding: 6% 8% 0;
                                width: 100%;
                                .fontFn(120,height);
                                font-size: 12px;
                                p{
                                    font-size: 16px;
                                }
                            }
                            .icon-more{
                                bottom: 24%;
                                left: 8%;
                                padding-left: 4%;
                                width: auto;
                                height: 20px;
                                background-position: left center;
                                background-size:20% auto;
                                span{
                                    display: block;
                                    .fontFn(12);
                                    color:#D93C45;
                                    position: relative;
                                    top:-2px;

                                }

                            }
                        }
                    }
                }
            } 
        } 
    }
</style>